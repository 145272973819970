@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url("./NotoSansKR-Light.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: normal;
  src: url("./NotoSansKR-Regular.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url("./NotoSansKR-Medium.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: bold;
  src: url("./NotoSansKR-Bold.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: lighter;
  src: url("./NotoSansKR-Thin.woff") format("woff");
}