.Publication {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;

  .title-box {
    text-align: center;
    margin-bottom: 16px;

    h1 {
      font-size: 1.8em;
      font-weight: bold;
    }
  }

  .paper-list {
    width: 90%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .inline {
    display: inline;
  }


  .more {
    margin-top: 15px;
    font-size: 1em;
    font-weight: bold;
    color: #ff6600;
    text-align: right;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: #cc5500;
    }
  }
}

/* 반응형 스타일 */
@media (max-width: 1250px) {
  .Publication {
    .paperCard {
      // width: 700px;
      padding: 16px;
      p {
        font-size: 0.85em;
      }
    }
  }
}

@media (max-width: 840px) {
  .Publication {
    .paperCard {
      // width: 500px;
      padding: 14px;
      p {
        font-size: 0.8em;
      }
    }
  }
}

@media (max-width: 500px) {
  .Publication {
    .title-box h1 {
      font-size: 1.5em;
    }

    .paperCard {
      // width: 90%;
      padding: 12px;
      p {
        font-size: 0.75em;
      }
    }

    .more {
      font-size: 0.9em;
    }
  }
}
