* {
  font-family : 'Noto Sans KR';
}

p {
  font-family : 'Noto Sans KR';
  text-decoration: none;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}
.banselect{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* 인터넷익스플로러 */
  user-select: none;
}