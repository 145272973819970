.register_modal_header {
  background-color: #292c3a;
  width: 100%;
  padding: 15px 0px;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: sticky;
  top:0;
  z-index: 5;
}
.register_modal_header h2 {
  color: white;
  font-size: 1.8em;
  font-weight: bold;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
}
.info p {
  font-size: 1.2em;
  margin-top: 10px;
}

.pw-box {
  width: 400px;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  .id-input {
    width: 350px;
    height: 20px;
    padding: 4px;
  }
}


.row-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}
.row-box2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p:nth-child(1) {
    margin-top: 10px;
  }
}


.title-box {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 2.3em;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p {
    text-align: center;
    width: 1000px;
    font-size: 1.1em;
    font-weight: 500;
    line-height: 31px;
  }
}


.Members {
  padding-bottom: 100px;
  .profile {
    width: 170px; // 자를 사이즈를 명시해준다.
    height: 170px;
    object-fit: cover;
    border-radius: 500px;
  }
  padding-top: 40px;
  h1 {
    font-size: 2.6em;
    font-weight: bold;
    margin-bottom: 50px;
  }
  .home {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .prof-box {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .name {
      margin-top: 20px;
      font-size: 1.8em;
      margin-right: 10px;
      font-weight: bold;
    }
    .desc {
      margin-top: 18px;
      justify-content: center;
      text-align: center;
      font-size: 1.1em;
      line-height: 28px;
      margin-bottom: 30px;
    }
  }

  .phd-box,
  .master-box {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 2.2em;
      font-weight: bold;
      margin-bottom: 50px;
    }

    .profile {
      border: 1px solid #acb0b7;
      width: 120px;
      height: 120px;
    }
    .container {
      // width: 500px;
      display: grid;
      grid-template-columns: 270px 270px 270px 270px; /* 명시적 2개 열 정의 */
      // grid-auto-rows: 100px; /* 그 외(암시적) 행의 크기 정의 */
      justify-content: center;
      .item {
        margin-bottom: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .name {
          margin-top: 20px;
          font-weight: bold;
          font-size: 1.5em;
        }
        .email {
          margin-top: 5px;
          line-height: 35px;
          font-size: 0.9em;
        }
        .mbti {
          margin-top: 5px;
          font-size: 0.9em;
          font-weight: bold;
          border-radius: 4px;
          cursor: pointer;
          padding: 6px 12px;
        }
        .interest {
          margin-top: 5px;
          margin-left: 10px;
          font-size: 0.9em;
          font-weight: bold;
          border-radius: 4px;
          cursor: pointer;
          padding: 6px 12px;
        }
      }
    }
  }

  .tooltip {
    white-space: pre-line;
    display: none;
    position: absolute;
    bottom: -20%;
    line-height: 22px;
    left: 50%;
    font-size: 0.9em;
    font-weight: 500;
    height: auto;
    width: max-content;
    letter-spacing: -0.25px;
    margin-top: 9px;
    padding: 7px 11px;
    text-align: center;
    z-index: 100;
    transform: translate(-44%, 110%);
  }

// 말풍선 테두리와 꼬리를 위한 before, after
  .tooltip::after {
    border-style: solid;
    border-width: 0 6px 8px 6.5px;
    content: "";
    display: block;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: -5px;
    width: 0;
    z-index: 1;
  }

  .tooltip::before {
    border-style: solid;
    border-width: 0 6px 8px 6.5px;
    content: "";
    display: block;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: -8px;
    width: 0;
    z-index: 0;
  }

}

.Research {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f6f9;

  .card {
    box-shadow: 0 5px 18px -7px rgba(0,0,0,0.4);
    width: 280px;
    height: max-content;
    border-radius: 20px;
    padding: 20px 25px;
    margin: 0 10px;
    margin-top: 60px;
    margin-bottom:100px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 1.3em;
      margin-top: 20px;
      text-align: center;
      line-height: 31px;
      height: 240px;
      flex-direction: column;
      justify-content: center;
    }
    
    h1 {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .image {
      width: 140px;
      height: 140px;
      object-fit: cover;
      border-radius: 600px;
      margin-bottom: 10px;
    }
    
    .profile img{
      width: 150px;
    }
  }
}



.Gallery {
  padding-bottom: 200px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .slick-list{
    border-radius: 10px;
  }
  .cardCover {
    width: 400px;
    overflow: hidden;
    border-width: 0;
    .card {
      align-items: center;
      width: 380px;
      height: 380px;
      object-fit: cover; 
      margin-bottom: 0;
      padding-bottom: 0;
      img {
        object-fit: cover;
      }
    }
  }
  .cardBox {
    height: max-content;
    margin: 30px 30px;
  }
}

.Contact {
  padding-bottom: 200px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f6f9;
  .backImage {
    margin-top: 20px;
    width: 500px;
    border-radius: 10px;
  }
  .info-box {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: ceenter;
    justify-content: center;
    .info-text {
      line-height: 30px;
      font-size: 1em !important;
      text-align: center;
    }
  }
  .icon {
    width: 34px;
    cursor: pointer;
    margin-right: 20px;
  }
  .customoverlay {
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #ffffff7a;
    border: #3E53BF 1px solid;
    margin-top: 50px;
    font-weight: bold;
  }
  .title {
    color: #000,
  }
  .welcomeBtn {
    margin-top: 10px;
    padding: 8px 16px;
    border-radius: 10px;
    border-width: 0;
    cursor: pointer;
    p {
      color: #fff;
      font-size: 1em;
      font-weight: 600;
    }
  }
}

.top__contents {
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub-text {
  font-size: 1.3em;
  font-weight: normal;
  margin-bottom: 20px;
}
.main-text {
  font-size: 2.8em;
  font-weight: 400;
}

@media all and (max-width: 1450px) {
  .Gallery {
    padding-bottom: 100px;
    width: 100vw;
    .cardCover {
      width: 350px;
      overflow: hidden;
      border-width: 0;
    
      .card {
        align-items: center;
        width: 350px;
        height: 350px;
        object-fit: cover; 
        img {
          object-fit: cover;
        }
      }
    }
    .cardBox {
      height: max-content;
    }
  }

  .Research {
    .card {
      width: 240px;
      padding: 20 20;
      p {
        font-size: 1.2em;
        margin-top: 20px;
        line-height: 28px;
        height: 210px
      }
      h1 {
        font-size: 1.8em;
        font-weight: bold;
        margin-bottom: 30px;
      }
      .image {
        width: 120px;
        height: 120px;
        margin-bottom: 5px;
      }
    }
  }
}

@media all and (max-width: 1250px) {

  .title-box {
    margin-top: 120px;
    h1 {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 25px;
    }
    p {
      width: 700px;
      font-size: 1.1em;
      font-weight: 500;
      line-height: 29px;
    }
  }

  .Research {
    .top__contents {
      margin-top: 60px;
      margin-bottom:70px;
      display: grid;
      justify-content: center;
      grid-template-columns: 350px 350px; 
      .card {
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 270px;
        // box-shadow: 0 5px 18px -7px rgba(0,0,0,0.4);
        // height: max-content;
        // border-radius: 20px;
        // padding: 30px 40px;
        // margin: 0 10px; 
        // margin-bottom: 30px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
          height: max-content;
        p {
          height: 190px;
          font-size: 1.2em;
          margin-top: 20px;
          text-align: center;
          line-height: 26px;
        }
        
        h1 {
          font-size: 1.8em;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .image {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 600px;
          margin-bottom: 6px;
        }
        
        .profile img{
          width: 150px;
        }
      }
    }
  }


  .Members {
    padding-bottom: 100px;
    .profile {
      width: 140px;
      height: 140px;
      border-radius: 500px;
    }
    padding-top: 40px;
    h1 {
      font-size: 2.1em;
      font-weight: bold;
      margin-bottom: 50px;
    }
    .home {
      width: 22px;
      height: 22px;
    }
    .prof-box {
      margin-top: 100px;
      .name {
        margin-top: 20px;
        font-size: 1.6em;
        margin-right: 8px;
      }
      .desc {
        margin-top: 16px;
        font-size: 1em;
        line-height: 26px;
        margin-bottom: 30px;
      }
    }

    .phd-box,
    .master-box {
      margin-top: 70px;
      h1 {
        font-size: 2em;
        margin-bottom: 50px;
      }
      .profile {
        border: 1px solid #acb0b7;
        width: 110px;
        height: 110px;
      }
      .container {
        width: 500px;
        grid-template-columns:  250px  250px  250px;
        .item {
          margin-bottom: 50px;
          .name {
            margin-top: 20px;
            font-weight: bold;
            font-size: 1.4em;
          }
          .email {
            margin-top: 5px;
            line-height: 35px;
            font-size: 0.8em;
          }
          .mbti {
            font-size: 0.8em;
            border-radius: 4px;
            padding: 6px 12px;
          }
          .interest {
            margin-left: 10px;
            font-size: 0.8em;
            border-radius: 4px;
            padding: 6px 12px;
          }
        }
      }
    }
  }
  .tooltip {
    font-size: 0.8em;
    margin-top: 9px;
    padding: 7px 11px;
  }


  .Gallery {
    padding-bottom: 100px;
    width: 100vw;
    .cardCover {
      width: 300px;
      overflow: hidden;
      border-width: 0;
    
      .card {
        align-items: center;
        width: 300px;
        height: 300px;
        object-fit: cover; 
        img {
          object-fit: cover;
        }
      }
    }
    .cardBox {
      height: max-content;
    }
  }

  .Contact {
    padding-bottom: 200px;
    width: 100vw;
    .backImage {
      margin-top: 20px;
      width: 400px;
      border-radius: 10px;
    }
    .info-box {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      align-items: ceenter;
      justify-content: center;
      .info-text {
        line-height: 27px;
        font-size: 0.9em !important;
        text-align: center;
      }
    }
    .icon {
      width: 30px;
      margin-right: 20px;
    }
    .customoverlay {
      padding: 8px 12px;
      border-radius: 20px;
      background-color: #ffffff7a;
      // border: #3E53BF 1px solid;
      margin-top: 50px;
      font-weight: bold;
    }
    .title {
      color: #000,
    }

    .welcomeBtn {
      margin-top: 10px;
      padding: 8px 16px;
      border-radius: 10px;
      border-width: 0;
      cursor: pointer;
      p {
        color: #fff;
        font-size: 0.9em;
        font-weight: 600;
      }
    }
  }
}


@media all and (max-width: 840px) {
  .title-box {
    margin-top: 120px;
    h1 {
      font-size: 1.8em;
      margin-bottom: 20px;
    }
    p {
      width: 500px;
      font-size: 0.9em;
      font-weight: 500;
      line-height: 26px;
    }
  }

  .Research {
    .top__contents {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      margin-bottom:70px;
      .card {
        box-shadow: 0 5px 18px -7px rgba(0,0,0,0.4);
        width: 300px;
        height: max-content;
        border-radius: 20px;
        padding: 30px 40px;
        margin: 0 10px; 
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          height: max-content;
          font-size: 1.2em;
          margin-top: 17px;
          text-align: center;
          line-height: 25px;
        }
        
        h1 {
          font-size: 1.7em;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .image {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 600px;
          margin-bottom: 3px;
        }
        
        .profile img{
          width: 150px;
        }
      }
    }
  }
    
  .Members {
    padding-bottom: 100px;
    .profile {
      width: 150px;
      height: 150px;
      border-radius: 500px;
    }
    padding-top: 40px;
    h1 {
      font-size: 1.9em;
      font-weight: bold;
      margin-bottom: 40px;
    }
    .home {
      width: 20px;
      height: 20px;
    }
    .prof-box {
      margin-top: 100px;
      .name {
        margin-top: 20px;
        font-size: 1.5em;
        margin-right: 7px;
        font-weight: bold;
      }
      .desc {
        margin-top: 20px;
        font-size: 0.9em;
        line-height: 28px;
        margin-bottom: 30px;
      }
    }

    .phd-box,
    .master-box {
      margin-top: 70px;
      h1 {
        font-size: 1.7em;
        font-weight: bold;
        margin-bottom: 50px;
      }
      .profile {
        border: 1px solid #acb0b7;
        width: 100px;
        height: 100px;
      }
      .container {
        width: 500px;
        grid-template-columns: 250px 250px; 
        .item {
          margin-bottom: 40px;
          .name {
            margin-top: 20px;
            font-size: 1.3em;
          }
          .email {
            margin-top: 5px;
            line-height: 30px;
            font-size: 0.8em;
          }
          .mbti {
            margin-top: 2px;
            font-size: 0.8em;
            border-radius: 4px;
            padding: 5px 10px;
          }
          .interest {
            margin-top: 2px;
            margin-left: 10px;
            font-size: 0.8em;
            border-radius: 4px;
            padding: 5px 10px;
          }
        }
      }
    }
  }
  .tooltip {
    font-size: 0.7em;
    margin-top: 9px;
    padding: 6px 10px;
  }

  .Gallery {
    padding-bottom: 100px;
    width: 100vw;
    .cardCover {
      width: 220px;
      overflow: hidden;
      border-width: 0;
    
      .card {
        align-items: center;
        width: 220px;
        height: 220px;
        object-fit: cover; 
        img {
          object-fit: cover;
        }
      }
    }
    .cardBox {
      height: max-content;
    }
  }

  .Contact {
    padding-bottom: 100px;
    
    .info-box {
      display: flex;
      margin-top: 18px;
      flex-direction: column;
      align-items: ceenter;
      justify-content: center;
      .info-text {
        line-height: 23px;
        font-size: 0.85em !important;
        text-align: center;
      }
    }
    .icon {
      width: 25px;
      margin-right: 14px;
    }
    .customoverlay {
      padding: 8px 12px;
      border-radius: 20px;
      background-color: #ffffff7a;
      border: #3E53BF 1px solid;
      margin-top: 50px;
      font-weight: bold;
    }
    .title {
      color: #000,
    }
  }

}



@media all and (max-width: 500px) {
  .title-box {
    margin-top: 100px;
    h1 {
      width: 310px;
      text-align: center;
      font-size: 1.3em;
      margin-bottom: 20px;
    }
    p {
      width: 300px;
      font-size: 0.8em;
      font-weight: 500;
      line-height: 22px;
    }
  }

  .Research {
    .top__contents {
      flex-direction: column;
      margin-top: 30px;
      margin-bottom: 60px;
      .card {
        box-shadow: 0 5px 18px -7px rgba(0,0,0,0.4);
        width: 230px;
        height: auto;
        border-radius: 20px;
        padding: 26px 30px;
        margin: 0 10px; 
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 0.9em;
          margin-top: 20px;
          text-align: center;
          line-height: 20px;
        }
        h1 {
          font-size: 1.4em;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .image {
          width: 100px;
          height: 100px;
          margin-bottom: 5px;
        }
        
        .profile img{
          width: 150px;
        }
      }
    }
  }
    
  .Members {
    padding-bottom: 100px;
    .profile {
      width: 110px;
      height: 110px;
      border-radius: 500px;
    }
    padding-top: 40px;
    h1 {
      font-size: 1.5em;
      margin-bottom: 30px;
    }
    .home {
      width: 20px;
      height: 20px;
    }
    .prof-box {
      margin-top: 100px;
      .name {
        margin-top: 20px;
        font-size: 1.2em;
        margin-right: 5px;
        font-weight: bold;
      }
      .desc {
        margin-top: 15px;
        font-size: 0.78em;
        line-height: 21px;
        margin-bottom: 30px;
      }
    }

    .phd-box,
    .master-box {
      margin-top: 70px;
      h1 {
        font-size: 1.3em;
        font-weight: bold;
        margin-bottom: 30px;
      }
      .profile {
        border: 1px solid #acb0b7;
        width: 95px;
        height: 95px;
      }
      .container {
        width: max-content;
        grid-template-columns: 180px 180px; 
        .item {
          margin-bottom: 40px;
          .name {
            margin-top: 20px;
            font-size: 1em;
          }
          .email {
            margin-top: 2px;
            line-height: 26px;
            font-size: 0.7em;
          }
          .mbti {
            margin-top: 4px;
            font-size: 0.7em;
            border-radius: 4px;
            padding: 5px 9px;
          }
          .interest {
            margin-top: 4px;
            margin-left: 10px;
            font-size: 0.7em;
            font-weight: bold;
            border-radius: 4px;
            padding: 5px 9px;
          }
        }
      }
    }

    .tooltip {
      bottom: -20%;
      left: 50%;
      font-size: 0.7em;
      font-weight: 500;
      height: auto;
      width: 80px;
      letter-spacing: -0.25px;
      margin-top: 9px;
      padding: 4px 6px;
      text-align: center;
      z-index: 100;
      transform: translate(-44%, 110%);
    }
  }

  .register_modal_header {
    padding: 15px 0px;
    h2 {
      font-size: 1.3em;
      font-weight: bold;
    }
  }

  .Gallery {
    padding-bottom: 100px;
    width: 100vw;
    .cardCover {
      width: 135px;
      overflow: hidden;
      border-width: 0;
    
      .card {
        align-items: center;
        width: 135px;
        height: 135px;
        object-fit: cover; 
        img {
          object-fit: cover;
        }
      }
    }
    .cardBox {
      height: max-content;
    }
  }

  .Contact {
    padding-bottom: 100px;

    .backImage {
      margin-top: 20px;
      width: 250px;
      border-radius: 10px;
    }
    .info-box {
      margin-top: 15px;
      .info-text {
        line-height: 20px;
        font-size: 0.8em !important;
        text-align: center;
      }
    }
    .icon {
      width: 20px;
      margin-right: 10px;
    }
    .customoverlay {
      padding: 8px 12px;
      border-radius: 20px;
      background-color: #ffffff7a;
      border: #3E53BF 1px solid;
      margin-top: 50px;
      font-weight: bold;
    }
    .title {
      color: #000,
    }

    .welcomeBtn {
      margin-top: 10px;
      padding: 6px 14px;
      border-radius: 8px;
      border-width: 0;
      cursor: pointer;
      p {
        color: #fff;
        font-size: 0.8em;
        font-weight: 600;
      }
    }
  }
}
