.Footer {
  background-color: #303234;
  color: #b4b5be;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .contents_root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1500px;
    p {
      line-height: 40px;
      font-size: 1.4em;
      font-weight: bold;
    }
    .infos {
      margin-top: 13px;
      p {
        line-height: 30px;
        font-size: 1em;
        font-weight: 400;
      }
    }
  }
}


.containerG {
  width: 380px;
  height: 380px;
  .slick-slide div { 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .slick-dots {
    margin-top: 20px;
    button::before {
      color: #888;
    }
  }
  .slick-prev, .slick-next {
    font-size: 15px !important;
  }
  
  .slick-prev:before, .slick-next:before  {
    content: '' !important;
  }
  .slick-dots {
    text-align: center;
  }
  
  .slick-dots li {
    display: inline-block; /* li 요소들을 가로로 표시합니다. */
    margin: 0 2px; /* 각각의 li 요소 사이의 간격을 조절합니다. */
    padding: 0; /* 불필요한 패딩을 제거합니다. */
    list-style: none; /* 리스트 마커를 제거합니다. */
  }
}

.card-list {
  padding: 20px 25px;
  .paperCard {
    width: 1000px;
    padding: 12px 30px;
    border-bottom: 1px solid #858585;
    p {
      display: inline;
      font-size: 1em;
      line-height: 28px;
    }
    .paper-link {
      cursor: pointer;
    }
  }

  .newLabel {
    align-items: center;
    background-color: red;
    color: white;
    font-weight: bold;
    font-size: 0.8em;
    padding: 2px 10px;
    border-radius: 30px;
    margin-right: 10px;
  }
}

.newsBox {
  padding: 20px 25px;
  width: 800px;
  .textBox {
    padding: 20px 24px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    .title {
      font-size: 1.5em;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 15px;
    }
    .date {
      font-size: 1.2em;
      margin-bottom: 15px;
    }
    .newsImg {
      width: 100%;
      margin-top: 15px;
    }
  }
}

.contents {
  margin-top: 10px;
  font-size: 1em;
  font-weight: 400;
  line-height: 30px;
}
.paperCard:last-of-type {
  border-bottom: none !important;
}

.map {
  width: 1000px;
  height: 540px;
  z-index: 0;
  border-radius: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.logo {
  height: 50px;
  pointer-events: none;
  margin-right: 10px;
}

.name-box {
  margin-left: 10px;
  .name {
    font-size: 1.3em;
    font-weight: bold;
  }

  .desc {
    pointer-events: none;
    font-size: 0.9em;
    font-weight: 400;
    color: #888;
    margin-top: 6px;
  }
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  .box {
    display: flex;
    justify-content: center;
    border: transparent;
    border-bottom: #cecece;
    border-width: 1px;
    border-style: solid;
  }
}


.navbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1400px;
  z-index: 22;
}

.navbar__logo {
  font-size: 18px;
  display: flex;
  list-style: none;
  padding-left: 0px;
  align-items: center;
  cursor: pointer;
}

.navbar__menu {
  display: flex;
  list-style: none;
  align-items: center;
  padding-left: 0;
}

.navbar__menu li {
  margin-left: 8px;
  font-size: 1.2em;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 50px;
  cursor: pointer;
}

.navbar__menu li:hover {
  color: #3E53BF;
  font-weight: 800;
}

.active li{ 
  background-color: #f4f6f9;
  color: #3E53BF;
  font-weight: 800;
}


.sidebar__menu { 
  .active li{ 
    background-color: #e1e6ec;
    color: #3E53BF;
  }
}

input[id="menuicon"] {
  display: none;
}

input[id="menuicon"] + label {
  display: block;
  width: 26px;
  height: 20px;
  position: fixed;
  right: -10%;
  cursor: pointer;
  transition: all 0.35s;
}

input[id="menuicon"]:checked + label {
  right: 5%;
}

input[id="menuicon"] + label span:nth-child(1) {
  top: 0%;
}

input[id="menuicon"] + label span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

input[id="menuicon"] + label span:nth-child(3) {
  bottom: 0%;
}

input[id="menuicon"]:checked + label span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

input[id="menuicon"]:checked + label span:nth-child(2) {
  opacity: 0;
}

input[id="menuicon"]:checked + label span:nth-child(3) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.sidebar {
  width: 230px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0%;
  right: -260px;
  z-index: 1;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transition: all 0.35s;
}


.sidebar__menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-top: 80px;
}

.submit {
  cursor: pointer;
  padding: 10px 0;
  width: 100%;
  background-color: #292c3a;
  display: flex;
  border-width: 0;
  justify-content: center;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: white; 

}
.sidebar__menu a > li {
  font-size: 1em;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 2px;
  font-weight: 600;
  text-align: center;
  display: flex;
  list-style: none;
}

.sidebar__menu form > button {
  font-size: 1.2em;
  padding: 10px 20px;
}
.sidebar__menu p{
  font-size: 1.3em;
  padding: 10px 20px;
}

.noMargin p{
  font-size: 1.5em;
}
.noMargin p, form button{
  font-size: 1.4em;
}


.sidebar__menu li :hover {
  color: #3E53BF;
}


input[id="menuicon"]:checked + label + div {
  right: 0;
}

input[id="menuicon"]:checked + label {
  z-index: 10;
}

input[id="menuicon"] + label span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 30px;
  background: #757575;
  transition: all 0.35s;
}


.noMargin {
  padding: 0 !important;
  padding-left: 10px !important;
}

@media screen and (max-width: 1650px) {
  .Footer {
    padding: 70px 0;
    flex-direction: row;
    justify-content: flex-start;
    .contents_root {
      display: flex;
      flex-direction: row;
      width: auto;
      margin-left: 100px;
    }
  }
}


@media all and (max-width: 1450px) {
  .containerG {
    width: 350px;
    height: 350px;
  }
  .Header {
    .navbar {
      padding: 0 40px;
      .logo-writing {
        font-size: 1.2em;
      }
      .navbar__menu li {
        margin-left: 4px !important;
        font-size: 1.1em;
        cursor: pointer;
      }
      
      .logo {
        height: 43px;
      }
    }
  }
  
}


@media screen and (max-width: 1250px) {
  .register_modal_header h2 {
    color: white;
    font-size: 1.5em !important;
    font-weight: bold;
  }

  .banselect {
    font-size: 1.2em !important;
  }

  .newsBox {
    padding: 20px 25px;
    width: 680px;
    .textBox {
      padding: 20px 24px;
      .title {
        font-size: 1.3em;
        font-weight: 700;
        line-height: 25px;
        margin-bottom: 15px;
      }
      .date {
        font-weight: 400;
        font-size: 0.9em;
        margin-bottom: 12px;
      }
      .newsImg {
        margin-top: 10px;
      }
    }
  }
  .contents {
    margin-top: 7px;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 24px;
  }
  
  .containerG {
    width: 300px;
    height: 300px;
    .slick-list {
      height: 300px;
    }
    .slick-dots li {
      width: 25px;
      height: 25px;
      display: inline-block; 
      margin: 0px; 
      padding: 0;
      list-style: none;
    }
  }
  .Header {
    .noMargin {
      padding: 0 !important;
      padding-left: 5px !important;
    }
    .navbar {
      height: 70px;
      padding: 0 40px;
      .logo-writing {
        font-size: 1.2em;
      }
      .navbar__menu li {
        margin-left: 1px !important;
        font-size: 1em;
        cursor: pointer;
      }
      
      .logo {
        height: 35px;
      }
      .name-box {
        margin-left: 3px;
        .name {
          font-size: 26px;
        }
        .desc {
          font-size: 0px;
          line-height: 0px;
          // font-size: 0.7em;
        }
      }
    }
  }

  .map {
    width: 700px;
    height: 540px;
    z-index: 0;
    border-radius: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
    
  .card-list {
    padding: 18px 20px;
    .paperCard {
      width: 700px;
      padding: 10px 25px;
      p {
        font-size: 0.9em;
        line-height: 25px;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .register_modal_header h2 {
    font-size: 1.3em !important;
  }
  .newsBox {
    padding: 15px 15px;
    width: 475px;
    .textBox {
      padding: 15px 15px;
      .title {
        font-size: 1.1em;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .date {
        font-weight: 400;
        font-size: 0.8em;
        margin-bottom: 5px;
      }
      .newsImg {
        margin-top: 10px;
      }
    }
  }
  .contents {
    margin-top: 7px;
    font-size: 0.7em;
    font-weight: 400;
    line-height: 20px;
  }
  
  .containerG {
    width: 220px;
    height: 220px;
    margin: -20px;
    margin-bottom: 20px;
    .slick-slide div {
      margin-bottom: 10px;
    }
    .slick-dots {
      text-align: center;
    }
    .slick-list {
      height: 220px;
    }
    .slick-dots li {
      width: 20px;
      height: 20px;
      display: inline-block; 
      margin: 0 1px; 
      padding: 0;
      list-style: none;
    }
    .slick-prev, .slick-next {
      font-size: 15px !important;
    }
  }


  .banselect {
    padding: 7px !important;
  }

  input[id="menuicon"] + label {
    right: 5%;
  }
  .map {
    width: 500px;
    height: 540px;
    z-index: 0;
    border-radius: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
    
  .card-list {
    padding: 16px 16px;
    .paperCard {
      width: 450px;
      padding: 10px 13px;
      p {
        font-size: 0.8em;
        line-height: 20px;
      }
    }
  }

  .Footer {
    .contents_root {
      p {
        font-size: 1.3em;
        font-weight: bold;
      }
      .infos {
        margin-top: 15px;
        p {
          line-height: 26px;
          font-size: 0.9em;
          font-weight: 400;
        }
      }
    }
  }

  .navbar {
    height: 65px;
    padding: 0 20px;
  }
  .logo {
    height: 45px;
  }
  
  .logo-writing {
    height: 35px;
    font-size: 1.2em;
    padding-top: 3px;
    margin-left: 6px;
  }
  .navbar__menu {
    display: none;
  }
}


@media screen and (max-width: 500px) {
  .register_modal_header h2 {
    font-size: 1em !important;
  }
  
  .newsBox {
    padding: 10px 10px;
    width: 315px;
    .textBox {
      padding: 10px 10px;
      .title {
        font-size: 0.8em;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 7px;
      }
      .date {
        font-weight: 400;
        font-size: 0.7em;
        margin-bottom: 5px;
      }
      .newsImg {
        margin-top: 10px;
      }
    }
  }
  .contents {
    margin-top: 7px;
    font-size: 0.6em;
    font-weight: 400;
    line-height: 16px;
  }
  .containerG {
    width: 135px;
    height: 135px;
    margin: -20px;
    margin-bottom: 20px;
    .slick-slide div {
      margin-bottom: 10px;
    }
    .slick-dots {
      margin-top: 0px;
      button::before {
        color: #888;
      }
    }
    .slick-prev, .slick-next {
      font-size: 15px !important;
    }
    
    .slick-prev:before, .slick-next:before  {
      content: '' !important;
    }
    .slick-dots {
      text-align: center;
    }
    .slick-list {
      height: 135px;
    }
    .slick-dots li {
      width: 10px;
      height: 10px;
      display: inline-block; 
      margin: 0 1px; 
      padding: 0;
      list-style: none;
    }
  }
  input[id="menuicon"] + label {
    right: 5%;
  }
  .map {
    width: 300px;
    height: 340px;
    border-radius: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .card-list {
    padding: 10px 12px;
    .paperCard {
      width: 290px;
      padding: 6px 10px;
      p {
        font-size: 0.6em;
        line-height: 13px;
      }
    }
  }

  .Footer {
    padding: 50px 20px;
    .contents_root {
      margin-left: 30px;
      p {
        width: 300px;
        font-size: 1em;
        line-height: 28px;
        font-weight: bold;
      }
      .infos {
        margin-top: 12px;
        p {
          line-height: 22px;
          font-size: 0.8em;
          font-weight: 400;
        }
      }
    }
  }

  .navbar {
    height: 55px;
    padding: 0 20px !important;
  }
  .logo {
    height: 35px !important;
  }

  .sidebar__menu li {
    font-size: 1.3em;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 13px;
    font-weight: 600;
    text-align: center;
    display: flex;
    list-style: none;
  }

}
